
        <template>
          <div class="vc-snippet-doc">
            <h2>color-picker 颜色选择</h2>
<h3>基础用法</h3>
<p>选择颜色，修改颜色</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">:style</span>=<span class="hljs-string">"{height: '50px', width: '50px', backgroundColor: color}"</span>&gt;</span>演示色块
        <span class="hljs-tag">&lt;<span class="hljs-name">ixu-color-picker</span> <span class="hljs-attr">v-model</span>=<span class="hljs-string">"color"</span> /&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
        <span class="hljs-keyword">return</span> {
            <span class="hljs-attr">color</span>: <span class="hljs-string">''</span>
        }
    }
}
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>高级用法</h3>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-1 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
   <span class="hljs-tag">&lt;<span class="hljs-name">ixu-input-color-picker</span> <span class="hljs-attr">v-model</span>=<span class="hljs-string">"color1"</span> /&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
        <span class="hljs-keyword">return</span> {
            <span class="hljs-attr">color1</span>: <span class="hljs-string">'rgba(34, 34, 34, 1)'</span>
        }
    }
}
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>value / v-model</td>
<td>绑定值</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>placeholder</td>
<td>输入框占位文本</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>size</td>
<td>—</td>
<td>string</td>
<td>medium / small / mini</td>
<td>—</td>
</tr>
</tbody>
</table>

          </div>
        </template>
        <script>
           //
//
//
//
//
// //
//
//
//
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({height: '50px', width: '50px', backgroundColor: _vm.color})},[_vm._v("演示色块\n    "),_c('ixu-color-picker',{model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1)}
var staticRenderFns = []

          const exportJavaScript =  {
    data() {
        return {
            color: ''
        }
    }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-1":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-input-color-picker',{model:{value:(_vm.color1),callback:function ($$v) {_vm.color1=$$v},expression:"color1"}})}
var staticRenderFns = []

          const exportJavaScript =  {
    data() {
        return {
            color1: 'rgba(34, 34, 34, 1)'
        }
    }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         
       </style>